<template>
    <div class="wrapper-landing">
      <LandingHero
        title="Crea tu lista de bodas multi-tienda & gratuita"
        subtitle="Guarda todos tus deseos y compártelos de forma elegante."
        origin="listaboda"
        listType="wedding"
        logoStyle="white"
      >
        <template v-slot:cover>
          <source
            media="(max-width: 991px)"
            class=""
            srcset="../../assets/img/landings/cover-celebracion-boda-d.jpg"
            alt=""
            loading="lazy"
          />
          <img
            class="ln-hero__inset"
            src="../../assets/img/landings/cover-celebracion-boda-d.jpg"
            alt=""
            loading="lazy"
          />
        </template>
      </LandingHero>
      <main class="bg-cercles">
        <LandingAppRatings />
  
        <section class="bb-container">
          <LandingFeaturesSimple title="Necesitas una lista de HelloBB si:"
          :use-carousel="false"
          >
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-q.svg" alt="" />
              </template>
              <template slot="title">
                Tu familia y amigos no paran de preguntarte qué quieres para la boda
              </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-mobile-cart.svg" alt="" />
              </template>
              <template slot="title"> Quieres pedir cosas de varias tiendas </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-hand-heart.svg" alt="" />
              </template>
              <template slot="title">
                Buscas una forma simpática y sencilla de compartir tus deseos
              </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-clipboard.svg" alt="" />
              </template>
              <template slot="title">
                Te gusta organizarte y no te quieres olvidar de nada
              </template>
            </LandingFeaturesSimpleItem>
          </LandingFeaturesSimple>
        </section>
      </main>
  
      <LandingFeaturesCover title="Una lista cargada de cosas buenas">
        <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/landings/cover-retailers-box.png" alt="" />
          </template>
          <template slot="titulo">
            Pide cualquier modelo, de cualquier marca, de cualquier tienda
          </template>
  
          <template slot="descripcion">
            <p>
              Copia cualquier enlace y guárdalo en tu lista. Libertad total para escoger
              justo lo que quieres.
            </p>
          </template>
        </LandingFeaturesCoverItem>
        <!-- <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/covers/cover-cashback.svg" alt="" />
          </template>
          <template slot="titulo"> Gana un 5% de lo que gastéis </template>
  
          <template slot="descripcion">
            <p>
              Obtendrás un cheque regalo por valor de un 5% de todo lo que tú, tus
              familiares y tus amigos compréis en la tienda de HelloBB (si llegáis a los
              500€).
            </p>
          </template>
        </LandingFeaturesCoverItem> -->
        <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/covers/cover-regalos-efectivo.svg" alt="" />
          </template>
          <template slot="titulo"> Recibe regalos o efectivo </template>
  
          <template slot="descripcion">
            <p>
              Tu familia y amigos podrán regalarte cosas o mandarte dinero para que las compres tú.
            </p>
          </template>
        </LandingFeaturesCoverItem>
        <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets//img/covers/mockup_aportaciones_ES.png" alt="" />
          </template>
          <template slot="titulo"> Ofrece la posibilidad de compartir las compras más caras </template>
  
          <template slot="descripcion">
            <p>
              Además, tu familia y amigos podrán colaborar con aportaciones parciales
                  para comprar las cosas más caras entre todos.
            </p>
          </template>
        </LandingFeaturesCoverItem>
      </LandingFeaturesCover>
      
  
      <main class="bg-cercles">
        <section class="bb-container">
          <LandingReviewsWedding />
          <LandingHablan />
        </section>
      </main>
  
      <LandingComoCols title="¿Cómo funciona?" subtitle="Tu lista de bodas digital">
        <LandingComoColsItem>
          <template slot="icono">
            <img src="../../assets/img/ico/ico-max-click.svg" alt="" />
          </template>
          <template slot="titulo">Elige qué quieres</template>
          <template slot="descripcion">
            <p>
              Escoge el objeto que quieras de cualquier tienda online, copia el enlace en
              HelloBB y guárdalo.
            </p>
            <p>
              También puedes tener una Hucha para reunir dinero para lo que
              quieras.
            </p>
          </template>
        </LandingComoColsItem>
        <LandingComoColsItem>
          <template slot="icono">
            <img src="../../assets/img/ico/ico-max-share.svg" alt="" />
          </template>
          <template slot="titulo">Comparte tus deseos</template>
          <template slot="descripcion">
            <p>Cuando quieras, comparte tu lista con tus familiares y amigos.</p>
            <p>
              Enviales un enlace para que accedan desde cualquier sitio y sin registrarse a
              una bonita lista personalizada por ti.
            </p>
          </template>
        </LandingComoColsItem>
        <LandingComoColsItem>
          <template slot="icono">
            <img src="../../assets/img/ico/ico-max-gift.svg" alt="" />
          </template>
          <template slot="titulo">Consigue los regalos</template>
          <template slot="descripcion">
            <p>
              Tus allegados podrán escoger el regalo de tu lista que más les guste y
              comprarlo. Te lo podrán mandar a ti o a ellos para dároslo en una visita.
            </p>
            <p>
              Para que nadie más lo compre, podrán marcar en la lista que ya está regalado.
            </p>
          </template>
        </LandingComoColsItem>
      </LandingComoCols>
  
      <LandingFooter />
    </div>
  </template>
  
  <script>
  import LandingFeaturesSlider from "./components/LandingFeaturesSlider.vue";
  
  import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
  import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";
  
  import LandingHero from "./components/LandingHero.vue";
  import LandingAppRatings from "./components/LandingAppRatings.vue";
  import LandingFeaturesCover from "./components/LandingFeaturesCover";
  import LandingFeaturesCoverItem from "./components/LandingFeaturesCoverItem";
  import LandingReviewsWedding from "./components/LandingReviewsWedding.vue";
  import LandingHablan from "./components/LandingHablan.vue";
  import LandingFooter from "./components/LandingFooter.vue";
  
  import LandingComoCards from "./components/LandingComoCards.vue";
  import LandingComoCardsItem from "./components/LandingComoCardsItem.vue";
  import LandingComoColsItem from "./components/LandingComoColsItem.vue";
  import LandingComoCols from "./components/LandingComoCols.vue";
  
  // import bbButton from "../../components/buttons/bbButton.vue";
  
  export default {
    name: "LandingWeddingES",
    metaInfo() {
      return {
        title: "HelloBB: tu lista de bodas multi-tienda & gratuita",
        meta: [
          { name: "description", content: "Haz la lista de tu boda con HelloBB." },
          // { name: "robots", content: "noindex" },
        ],
      };
    },
    components: {
      LandingComoCols,
      LandingComoColsItem,
      LandingAppRatings,
      LandingHablan,
      LandingReviewsWedding,
      LandingFooter,
      LandingHero,
      LandingFeaturesCover,
      LandingFeaturesCoverItem,
      LandingFeaturesSlider,
      LandingFeaturesSimple,
      LandingFeaturesSimpleItem,
      LandingComoCards,
      LandingComoCardsItem,
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  